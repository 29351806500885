import React from 'react'
import PropTypes from 'prop-types'
import styles from './IntroLargeTitle.module.scss'

const IntroLargeTitle = props => (
  <h1 className={styles.title}>
    {props.text}
  </h1>
)

IntroLargeTitle.propTypes = {
  text: PropTypes.string.isRequired
}

export default IntroLargeTitle
